import React from 'react'
import {
    NavigationMenuContent,
    NavigationMenuItem,
    NavigationMenuLink,
    NavigationMenuList,
    NavigationMenuTrigger,
} from '@/components/renderjuice/navigation-menu'
import { cn } from '@/lib/utils'
import Button from '@/components/renderjuice/GradientButton'
import { ImageKit } from '@/components/global/ImageKit'

/**
 * This is somewhat of a hack to hook into the
 * `data-state` attribute via spreading props.
 * Talk to stevie if more info is needed.
 */
const MenuButton = React.forwardRef<
    React.ElementRef<'button'>,
    React.ComponentPropsWithoutRef<'button'>
>((props, ref) => {
    const dataState = props['data-state']
    return (
        <button aria-label='Mobile Menu' {...props} ref={ref}>
            <svg
                stroke='currentColor'
                data-state={dataState}
                fill='none'
                viewBox='0 0 24 24'
                className='size-5 data-[state=open]:hidden'
            >
                {/* Hamburger */}
                <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth='2'
                    d='M4 6h16M4 12h16M4 18h16'
                />
            </svg>
            <svg
                data-state={dataState}
                stroke='currentColor'
                fill='none'
                viewBox='0 0 24 24'
                className='size-5 data-[state=closed]:hidden'
            >
                <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth='2'
                    d='M6 18L18 6M6 6l12 12'
                />
            </svg>
        </button>
    )
})

/**
 * NOTE:
 * Due to the way the NavigationMenu works with NavigationMenuContent, the backdrop-blur and opacity
 * is not consistently applied to both the header menu and the dropdown's content
 *
 * This is likely because the backdrop-blur is being double applied in the NavigationMenuContent component from the header
 * due to it being a child of the NavigationMenu component
 *
 * Not optimal, but most wont notice this.
 */
const MobileListItem = React.forwardRef<
    React.ElementRef<'a'>,
    React.ComponentPropsWithoutRef<'a'> & { icon: React.ReactNode }
>(({ className, title, children, icon, ...props }, ref) => {
    return (
        <li>
            <NavigationMenuLink asChild>
                <a
                    ref={ref}
                    className={cn(
                        `
                        grid
                        grid-cols-[1fr_9fr]
                        gap-x-2
                        select-none
                        rounded-sm
                        px-2
                        py-2
                        leading-none
                        no-underline
                        outline-none
                        transition-colors
                        hover:bg-accent
                        hover:text-accent-foreground
                        focus:bg-accent
                        focus:text-accent-foreground
                        text-neutral-200`,
                        className,
                    )}
                    {...props}
                >
                    {icon && (
                        <div className='h-full items-center rounded-sm bg-[#21232A] hover:bg-sweet-black/80 flex justify-center'>
                            {icon}
                        </div>
                    )}
                    <div>
                        <div className='text-xs font-medium leading-non mb-0.5'>
                            {title}
                        </div>
                        {children && (
                            <p className='line-clamp-2 text-xs leading-snug text-muted-foreground'>
                                {children}
                            </p>
                        )}
                    </div>
                </a>
            </NavigationMenuLink>
        </li>
    )
})

const MobileLinkNoIcon = React.forwardRef<
    React.ElementRef<'a'>,
    React.ComponentPropsWithoutRef<'a'>
>(({ className, title, children, ...props }, ref) => {
    return (
        <li>
            <NavigationMenuLink asChild>
                <a
                    ref={ref}
                    className={cn(
                        `
                        grid
                        grid-cols-[1fr]
                        gap-x-2
                        select-none
                        rounded-sm
                        px-2
                        py-2
                        leading-none
                        no-underline
                        outline-none
                        transition-colors
                        hover:bg-accent
                        hover:text-accent-foreground
                        focus:bg-accent
                        focus:text-accent-foreground`,
                        className,
                    )}
                    {...props}
                >
                    <div>
                        <h5 className='x-text-gradient-indigo-right inline-block text-sm font-medium leading-non mb-0.5'>
                            {title} &rarr;
                        </h5>
                    </div>
                </a>
            </NavigationMenuLink>
        </li>
    )
})

const SmallNavigationMenuBlock = ({ pages }) => {
    return (
        <NavigationMenuList className='w-full sm:hidden px-4 group flex flex-1 list-none justify-between items-center space-x-1'>
            <NavigationMenuItem>
                <NavigationMenuLink className='flex items-center gap-1' asChild>
                    <a href='/' className='flex items-center gap-1'>
                        <ImageKit
                            src='/logos/logo.webp'
                            alt='RenderJuice Logo'
                            transformation={[{ width: 32 }, { height: 32 }]}
                            width={32}
                            height={32}
                        />
                        <span className='text-base font-medium'>
                            renderjuice
                        </span>
                    </a>
                </NavigationMenuLink>
            </NavigationMenuItem>
            <div className='flex items-center'>
                <NavigationMenuLink
                    href={import.meta.env.PUBLIC_RENDERJUICE_APP_URL}
                    className='text-sm mr-2'
                >
                    Login
                </NavigationMenuLink>
                <Button
                    variant='gradient'
                    href={import.meta.env.PUBLIC_RENDERJUICE_APP_URL}
                >
                    Sign Up
                </Button>
                <NavigationMenuItem>
                    <NavigationMenuTrigger
                        asChild
                        className='block sm:hidden mr-0'
                    >
                        <MenuButton />
                    </NavigationMenuTrigger>

                    <NavigationMenuContent className='gap-y-4 w-[100vw] bg-black/95 backdrop-filter shadow-thick px-4'>
                        <ul>
                            <MobileLinkNoIcon title='Pricing' href='/pricing' />
                        </ul>
                        {/*<h4 className='mb-1 px-2 text-muted-foreground text-xs'>Resources</h4> */}
                        <ul className='grid gap-x-2 px pb-6 pt-0 md:w-[400px] lg:w-[500px] lg:grid-cols-[.75fr_1fr]'>
                            <MobileListItem
                                href={pages[0].href}
                                title={pages[0].title}
                                icon={pages[0].icon}
                            >
                                {pages[0].subtitle}
                            </MobileListItem>
                            <MobileListItem
                                href={pages[1].href}
                                title={pages[1].title}
                                icon={pages[1].icon}
                            >
                                {pages[1].subtitle}
                            </MobileListItem>
                        </ul>
                        <h5 className='pt-2 mb-1 px-2 hidden'>Platform</h5>
                        {/*
                        <ul className='grid gap-x-2 px pb-6 pt-0 md:w-[400px] lg:w-[500px] lg:grid-cols-[.75fr_1fr] hidden'>
                            <MobileListItem
                                href={navigationItems[0].href}
                                title={navigationItems[0].title}
                            >
                                {navigationItems[0].subtitle}
                            </MobileListItem>
                            <MobileListItem
                                href={navigationItems[1].href}
                                title={navigationItems[1].title}
                            >
                                {navigationItems[1].subtitle}
                            </MobileListItem>
                            <MobileListItem
                                href={navigationItems[2].href}
                                title={navigationItems[2].title}
                            >
                                {navigationItems[2].subtitle}
                            </MobileListItem>
                        </ul>
                        */}
                    </NavigationMenuContent>
                </NavigationMenuItem>
            </div>
        </NavigationMenuList>
    )
}

export default SmallNavigationMenuBlock
