import MediumNavigationMenuBlock from '@/components/blocks/navigation/MediumNavigationMenuBlock'
import SmallNavigationMenuBlock from '@/components/blocks/navigation/SmallNavigationMenuBlock'
import { NavigationMenu } from '@/components/renderjuice/navigation-menu'
import {
    BookOpenIcon,
    ChartBarIcon,
    SparklesIcon,
} from '@heroicons/react/24/outline'

const smallNavLinks = []
const pages = [
    {
        title: 'Docs',
        subtitle: 'Learn more about how to use Renderjuice',
        href: '/docs',
        icon: <BookOpenIcon className='w-4 h-4' />,
    },
    {
        title: "What's new?",
        subtitle: 'The latest product updates, features, and more.',
        href: '/whats-new',
        icon: <SparklesIcon className='w-4 h-4' />,
    },
    {
        title: 'Insight',
        subtitle: 'Monitor and analyze as you render',
        icon: <ChartBarIcon className='w-4 h-4' />,
    },
]

const navigationItems = [
    {
        title: 'Performance & Power',
        subtitle: 'Accelerated rendering capabilities',
        href: '/performance',
    },
    {
        title: 'Insight',
        subtitle: 'Monitor and analyze as you render',
    },
    {
        title: 'Data security',
        subtitle: "You're safe and secure with us",
        href: '/pricing',
    },
]

type MediumNavigationLinks =
    | {
          title: string
          href?: string
          sublinks: {
              title: string
              href: string
              description: string
          }[]
      }
    | {
          title: string
          href: string
      }

const resources = [
    {
        title: 'Docs',
        href: '/docs',
        description: 'Learn more about how to use Renderjuice',
    },
    {
        title: "What's New",
        href: '/whats-new',
        description:
            'The latest product updates, features, releases, and more.',
    },
    {
        title: 'Contact',
        href: '/contact',
        description: 'Get in touch with us for more information or support.',
    },
]

const links: MediumNavigationLinks[] = [
    {
        title: 'Pricing',
        href: '/pricing',
    },
    {
        title: 'Docs',
        href: '/docs',
    },
    {
        title: "What's New?",
        href: '/whats-new',
    },
    //{
    //  title: "Platform",
    //  href: "/platform",
    //  sublinks: [
    //    {
    //      title: "Introduction",
    //      href: "/docs",
    //      description: "Re-usable components built using Radix UI and Tailwind CSS."
    //    },
    //    {
    //      title: "Introduction",
    //      href: "/docs",
    //      description: "Re-usable components built using Radix UI and Tailwind CSS."
    //    },
    //    {
    //      title: "Introduction",
    //      href: "/docs",
    //      description: "Re-usable components built using Radix UI and Tailwind CSS."
    //    }
    //  ]
    //},
    //{
    //  title: "Resources",
    //  href: "/resources",
    //  sublinks: resources
    //},
    {
        title: 'Log In',
        href: import.meta.env.PUBLIC_RENDERJUICE_APP_URL,
    },
]

export default function NavigationMenuSection({
    className = '',
}: { className?: string }) {
    return (
        <header
            className={`
                fixed
                inset-x-0
                bg-black/90
                backdrop-blur-3xl
                shadow-thick
                backdrop-filter
                py-1
                z-50
                border-none
                px-0
            `}
        >
            <div
                className='
                    md:px-12
                    md:max-w-5xl
                    h-full
                    mx-auto
            '
            >
                <NavigationMenu
                    className={`
                    first:contents
                    w-full
                    relative
                    z-10
                    flex
                    md:px-0
                    md:flex-1
                    md:items-center
                    lg:max-w-xl
                    md:grid
                    md:w-full
                    md:h-full
                    `}
                >
                    <SmallNavigationMenuBlock pages={pages} />
                    <MediumNavigationMenuBlock links={links} />
                </NavigationMenu>
            </div>
        </header>
    )
}
