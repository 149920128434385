import {
    ListItem,
    NavigationMenuContent,
    NavigationMenuItem,
    NavigationMenuLink,
    NavigationMenuList,
    NavigationMenuTrigger,
} from '@/components/renderjuice/navigation-menu'
import { cva } from 'class-variance-authority'
import Button from '@/components/renderjuice/GradientButton'
import { ImageKit } from '@/components/global/ImageKit'

const navigationMenuTriggerStyle = cva(
    'group inline-flex h-10 w-max items-center justify-center rounded-md bg-transparent px-4 py-2 text-sm font-medium transition-colors hover:text-accent-foreground focus:text-accent-foreground focus:outline-none disabled:pointer-events-none disabled:opacity-50',
)
type NavigationLinkWithNoSubmenu = {
    title: string
    href: string
}

type NavigationLinkWithSubmenu = {
    title: string
    href: string
    sublinks: {
        title: string
        href: string
        description: string
    }[]
}

type MediumNavigationLinks =
    | NavigationLinkWithSubmenu
    | NavigationLinkWithNoSubmenu

const MediumNavigationMenuBlock = ({
    links,
}: { links: MediumNavigationLinks[] }) => {
    return (
        <>
            <NavigationMenuList className='hidden space-x-1 sm:flex sm:items-center sm:justify-between md:w-full md:list-none md:items-center md:justify-between lg:justify-center'>
                <div className='flex items-center'>
                    <NavigationMenuItem>
                        <NavigationMenuLink
                            href='/'
                            className='flex items-center gap-2'
                        >
                            <ImageKit
                                src='/logos/logo.webp'
                                transformation={[{ width: 32, height: 32 }]}
                                className='h-8'
                                width={32}
                                height={32}
                                alt='renderjuice logo'
                            />
                            <span className='text-base font-medium'>
                                renderjuice
                            </span>
                        </NavigationMenuLink>
                    </NavigationMenuItem>
                </div>
                <div className='flex items-center gap-2 text-neutral-200'>
                    {links.map((link) => (
                        <NavigationMenuItem key={link.title}>
                            {'sublinks' in link ? (
                                <>
                                    <NavigationMenuTrigger>
                                        {link.title}
                                    </NavigationMenuTrigger>
                                    <NavigationMenuContent className='shadow-thick bg-black/95 backdrop-filter'>
                                        <ul className='shadow-thick grid gap-3 p-4 backdrop-filter md:w-[500px] md:grid-cols-2 lg:w-[600px] lg:grid-cols-2'>
                                            {link.sublinks.map((sublink) => (
                                                <ListItem
                                                    key={sublink.title}
                                                    href={sublink.href}
                                                    title={sublink.title}
                                                >
                                                    {sublink.description}
                                                </ListItem>
                                            ))}
                                        </ul>
                                    </NavigationMenuContent>
                                </>
                            ) : (
                                <NavigationMenuLink
                                    href={link.href}
                                    className={navigationMenuTriggerStyle()}
                                >
                                    {link.title}
                                </NavigationMenuLink>
                            )}
                        </NavigationMenuItem>
                    ))}
                    <NavigationMenuItem>
                        <NavigationMenuLink
                            href={import.meta.env.PUBLIC_RENDERJUICE_APP_URL}
                            className={navigationMenuTriggerStyle()}
                            asChild
                        >
                            <Button variant='gradient' className='-ml-6'>
                                Sign Up
                            </Button>
                        </NavigationMenuLink>
                    </NavigationMenuItem>
                </div>
            </NavigationMenuList>
        </>
    )
}

export default MediumNavigationMenuBlock
